export const useTablesort = (
  data: Array<any>,
  column: string,
  direction: string
) => {
  const sortedData = data.sort((a, b) => {
    if (a[column] < b[column]) {
      return -1
    }
    if (a[column] > b[column]) {
      return 1
    }
    return 0
  })

  if (direction === 'desc') {
    let tmp = sortedData.reverse()
    return tmp
  }
  return sortedData
}
